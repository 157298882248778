<template>
  <div class="item-menu"
       v-bind:class="classCols"
       v-if="mode!='list' || $route.params.slug != $const.MENU_PIZZA || !isMobile()"
       @click="isHalf ? show(true) : show()"
       data-toggle="tooltip"
       v-bind:title="item.description">
    <div class="inner">
      <div class="menu-image bg1">
        <img v-if="item.image"
               :src="item.image.desktop_thumbnail" :alt="item.name"/>
      </div>
      <div class="bot">
        <div class="menu-name" style="z-index: 1">{{ item.name }}</div>

        <div v-if="item.marketing_description" class="menu-description  marketing_description" style="z-index: 1">
          <ellipsis :data="item.marketing_description"
                    :line-clamp="3"
                    :line-height="'24px'"></ellipsis>
        </div>

        <div class="menu-price" style="z-index: 1;"
             v-bind:class="{ 'border-top': borderTop(item) }"
             v-if="checkPosition(this)">
          <div v-if="price(item)"
               style="width: 100%; text-align: right; line-height: 30px">
            <span v-if="!isNaN(item.display_price)"
                  v-html="generatePrice(item)"></span>
            <span v-else>
                {{ item.display_price }}
              </span>
          </div>
          <div v-else class="btn btn-success btn-add-single-item text-left" style="width: 100%;"
               v-on:click.stop="addToBasket">
            {{$t('button_add')}}
            <span class="pull-right" v-html="generatePrice(item, false)">
                    <!--{{
                          $t('currency_display', {
                              currency: $const.CURRENCY,
                              amount: formatNumDisplay(item.display_price)
                          })
                      }}-->
                </span>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="item-menu mode-view-list "
       v-else
       data-toggle="tooltip"
       v-bind:title="item.description">
    <div class="flex" style="width: 100%">
      <div class="while-tooltip flex1" style="flex-grow: 1.5" @click="show">
        <div style="position: relative; height: 100%">
          <div class="menu-image bg1" style="height: 100%;">
            <img v-if="item.image" v-bind:src="item.image.desktop_thumbnail"
                 style="position: unset; object-fit: none; " :alt="item.name">
          </div>
          <div class="flag-customise" v-if="checkPosition(this)" style=" ">
            <span class="" style=" line-height: 25px; ">Customise</span>
          </div>
        </div>
      </div>
      <div class="item-info flex1 flex pr-10 pl-10" style="flex-direction: column;flex-grow: 2">
        <div class="flex1">
          <div class="menu-name" style="z-index: 1">{{ item.name }}</div>
          <div class="menu-description marketing_description" style="z-index: 1">
            <nl2br tag="div" v-if="item.marketing_description" :text="item.marketing_description"/>
          </div>
        </div>
        <div class="p10 text-black" v-if="checkPosition(this)">
          <select class="form-control t13" v-model="selectedVariant"
                  style="-webkit-appearance: menulist; -moz-appearance: menulist; appearance: menulist;">
            <option v-for="(obj,index) in dataVariant" :key="index" v-bind:value="obj">
              {{obj.label}}
            </option>
          </select>
        </div>
      </div>
      <div class="item-action flex pr-10 pl-10" style="flex-direction: column" v-if="checkPosition(this)">
        <div class="flex1 pt-10">
                    <span class="item-price t13 text-black">
                    {{
                          $t('currency_display', {
                              currency: $const.CURRENCY,
                              amount: formatNumDisplay(selectedVariant.price)
                          })
                      }}
                </span>
        </div>
        <div class="" style="z-index: 1;margin-bottom: 10px">
          <div style="width: 100%; text-align: right; line-height: 30px">
            <div class="btn btn-success btn-add-single-item " style="width: 100%;"
                 v-on:click.stop="addToBasket">
              {{$t('button_add')}}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Ellipsis from '@/components/partials/Ellipsis'
import vSelect from 'vue-select'
import {mapGetters} from 'vuex'

export default {
  props: ['item', 'cols', 'uuid', 'isHalf', 'onlyLunch'],
  components: {
    vSelect,
    Ellipsis
  },
  mounted () {
    var vm = this
    this.$root.$on('onChangeModeView', (mode) => {
      vm.mode = mode
    })
    this.mode = this.isMobile() ? this.$session.get('modeView') || 'grip' : 'grip'
    if (this.item != undefined && this.item.first_layers) {
      for (let i = 0; i < this.item.first_layers.length; i++) {
        if (this.item.first_layers[i] != undefined && this.item.first_layers[i].second_layers) {
          for (let j = 0; j < this.item.first_layers[i].second_layers.length; j++) {
            let price = this.item.first_layers[i].price + this.item.first_layers[i].second_layers[j].price + this.item.price
            this.dataVariant.push({
              first: this.item.first_layers[i],
              price: price,
              second: this.item.first_layers[i].second_layers[j],
              label: this.item.first_layers[i].name + ' - ' + this.item.first_layers[i].second_layers[j].name
            })
            if (price === this.item.min_price) {
              this.selectedVariant = this.dataVariant[this.dataVariant.length - 1]
            }
          }
        } else {
          let price = this.item.first_layers[i].price + this.item.price

          this.dataVariant.push({
            first: this.item.first_layers[i],
            second: {},
            price: price,
            label: this.item.first_layers[i].name
          })
          if (price === this.item.min_price) {
            this.selectedVariant = this.dataVariant[this.dataVariant.length - 1]
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedCategory: 'selectedCategory',
      selectedSubCategory: 'selectedSubCategory'
    })
  },
  data () {
    return {
      mode: 'grip',
      classCols: 'cols-' + this.cols,
      selectedVariant: {},
      dataVariant: [],
      routeSub: this.$route.params.sub
    }
  },
  methods: {
    generatePrice (item, hasSize = true) {
      return this.$t('format_currency', {
        currency: this.$const.CURRENCY,
        amount: this.formatNumDisplay(item.display_price),
        start: '<span class="t13"><span class="font-08em cl-78838c fw-normal">',
        end: '</span></span>'
      })
    },
    borderTop (item) {
      if (item) {
        if (this.isHalf) {
          return true
        } else if (item && item.first_layers && item.first_layers !== undefined) {
          return item.first_layers.length > 1 || item.first_layers.length === 1 && item.first_layers[0].second_layers.length > 1
        }
      }

      return false
    },
    price (item) {
      if (this.isHalf) {
        return true
      } else if (item && item.first_layers && item.first_layers !== undefined) {
        return item.first_layers.length >= 1 || item.first_layers.length === 1 && item.first_layers[0].second_layers.length > 1
      }
      return false
    },
    /**
         * adding item to cart
         * */
    addToBasket () {
      if (!this.checkPosition(this)) {
        let path = this.$route.fullPath.indexOf('/') != 0 ? this.$route.fullPath : this.$route.fullPath.substr(1)
        this.$router.push({'name': 'home', query: {isShowWarning: 1, callback: path, focusLocalization: true}})
      } else {
        let pro = {
          item: this.item,
          id: this.item.uuid,
          name: this.item.name,
          price: 0,
          first: {},
          second: {}
        }
        let firstSelected = {}
        let secondSelected = {}
        let price = parseFloat(this.item.price > 0 ? this.item.price : 0)
        if ((this.mode != 'list' || this.$route.params.slug != this.$const.MENU_PIZZA) && this.item.first_layers) {
          firstSelected = this.item.first_layers.length === 1 ? this.item.first_layers[0] : []
          if (firstSelected.length === 1 && firstSelected.price > 0) {
            price += parseFloat(firstSelected.price)
          }
          if (firstSelected.length === 1 && firstSelected.second_layers.length == 1 && firstSelected.second_layers[0].price > 0) {
            price += parseFloat(firstSelected.second_layers[0].price)
            secondSelected = firstSelected.second_layers[0]
          }
        } else if (this.selectedVariant && !this.empty(this.selectedVariant)) {
          firstSelected = this.selectedVariant.first
          secondSelected = this.selectedVariant.second
          price += this.selectedVariant.price
        }

        // add to cart
        let item = {
          'group': {},
          'selling_start': this.item.selling_start,
          'products': []
        }

        item.group['outlet_code'] = this.getOutletCode(this)
        item.group['tier'] = this.$cart.tier
        item.group['order_type'] = this.$cart.order_type
        item.group['order_time'] = this.getTimePickerDate(this)
        item.group['product_type'] = this.item.product_type
        item.group['combo_id'] = null
        item.group['quantity'] = 1
        item.group['category_uuid'] = this.$cart.category_id

        let product = {}
        product.category_id = this.$cart.category_id
        product.combo_group_id = null
        product.product_id = this.item.uuid
        product.first_id = firstSelected ? firstSelected.uuid : null
        product.second_id = secondSelected ? secondSelected.uuid : null
        product.toppings = {}
        product.toppings.remove = []
        product.toppings.extra = []
        item.products.push(product)
        // add to cart
        this.$cart.insert(this, item)

        pro.price = price
        pro.first = firstSelected
        pro.second = secondSelected
        this.eventTrackingAddToCart(this, pro, 'main', this.$const.CARD_ITEM_TYPE.SINGLE)
      }
    },
    show (isHalf = false) {
      let vm = this

      if (!this.checkPosition(this)) {
        let path = this.$route.fullPath.indexOf('/') != 0 ? this.$route.fullPath : this.$route.fullPath.substr(1)
        this.$router.push({'name': 'home', query: {isShowWarning: 1, focusLocalization: true, callback: path}})
      } else {
        let slug = this.selectedCategory ? this.selectedCategory.slug : null
        let slugSub = this.selectedSubCategory ? this.selectedSubCategory.slug : null
        if (isHalf) {
          let itemData = {uuid: vm.$const.HALF_HALF_UUID}

          if (this.onlyLunch) {
            itemData.uuid = vm.$const.HALF_HALF_LUNCH_UUID
            vm.redirectToMenuPage(slug, vm.$const.HEADER_MENU.HALF_HAlF, vm.$const.HEADER_MENU.LUNCH_SET)
          } else {
            vm.redirectToMenuPage(slug, vm.$const.HEADER_MENU.HALF_HAlF)
          }
          this.evenTrackingProductClick(this, itemData, 'direct-product-selection')
        } else {
          this.evenTrackingProductClick(this, this.item, 'direct-product-selection')
          vm.redirectToMenuPage(slug, slugSub, vm.item.uuid)
        }
      }
    }
  }
}

</script>
<style scoped>
  @media (max-width: 990px) {
    .list-item-menu .item-menu.mode-view-list {
      width: calc(100% - 12px) !important;
      display: flex;
      flex-direction: row;
      padding-bottom: 0;

    }

  }

  .list-item-menu .item-menu.mode-view-list {
    width: calc(100% - 12px);
    padding-bottom: 0;

  }

  .list-item-menu .item-menu.mode-view-list .menu-image {
    height: 100%;
    min-height: 160px;
  }

  .list-item-menu .item-menu.mode-view-list .flag-customise {
    height: 30px;
    width: 95%;
    background: hsla(0, 0%, 100%, .9);
    position: absolute;
    top: 30%;
    left: 0;
    text-align: center;
    border: 2px solid hsla(0, 0%, 100%, .9);
    border-radius: 25px;
  }
</style>
